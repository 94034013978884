import React from "react";

import { Api } from "../api/index";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

class DeleteUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }


    close = (isDeleted) => {
        this.setState({ isLoading: false })
        this.props.onClose( isDeleted);
    }



    saveDisabled =() => {
        if ( this.state.isLoading === true ) return true
        if ( Number.isInteger(this.props.userToDelete.id ) !== true) return true
        return false
    }

    deleteUser =() => {
        if (this.saveDisabled()) {
            return alert('Invalid user')
        }
        this.setState({ isLoading: true })
        Api.delete(`/users/${this.props.userToDelete.id}`)
            .then(() => this.close(true))
            .catch((err) => alert(err))
            .finally(() => this.setState({isLoading: false}))
    }
    render() {
        return (
            <Modal show={ this.props.show }>
                <Modal.Header>
                    <Modal.Title> Are you sure you want to delete this user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <strong>name:</strong> <code>{this.props.userToDelete.first_name} {this.props.userToDelete.last_name}</code>
                    </div>
                    <div>
                        <strong>phone:</strong>  <code>{this.props.userToDelete.phone}</code>

                    </div>
                    { (this.props.userToDelete.email || '').trim().length > 0 &&
                        <div>
                            <strong>email:</strong>  <code>{this.props.userToDelete.email}</code>
                        </div>
                    }
                    <div>
                        <strong>company:</strong>  <code>{this.props.userToDelete.company_name}</code>
                    </div>
                    { (this.props.userToDelete.accounts || []).length > 0 &&
                        <div>
                            <strong>accounts:</strong>  <code>{this.props.userToDelete.accounts}</code>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => this.close(false)}>
                        Close
                    </Button>
                    <Button variant="danger"
                            disabled={this.saveDisabled()}
                            onClick={() => this.deleteUser()}>
                        {this.state.isLoading && <Spinner animation="border" role="status">
                            <span className="sr-only"></span>
                        </Spinner>}
                        {!this.state.isLoading && 'Delete User'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default DeleteUserModal;

