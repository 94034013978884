import React from "react";
import { Api } from "../api/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from "react-bootstrap/Badge";
import {Link} from "react-router-dom";

class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            installsStats: [],
            totalInstalls: 0
        };
    }
    componentDidMount() {
        this.loadInstallStats()
    }

    loadInstallStats = () => {
        Api.get('/companies/app_installs').then((response) => {
            const appInstalls = response.data;
            const totalInstalls = appInstalls.reduce((total, stat) => stat.cnt + total,0)
            this.setState({ installsStats: response.data, totalInstalls });
        })
    }


    render() {
        return (
            <Row className="justify-content-md-center mt-5">
                <Col xs="12" className="text-left mt-3">
                    <Link variant="primary" to={ {pathname: '/react_app' } }>
                        Companies
                    </Link>
                </Col>
                <Col xs="12">
                    <div className="text-center mb-3">
                        Total installs: <Badge pill bg="primary">{this.state.totalInstalls}</Badge>
                    </div>
                </Col>
                <Col xs="12" lg="6">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>installs</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.installsStats.map((stat, index) =>
                            <tr key={index}>
                                <td>{stat.name}</td>
                                <td>{stat.cnt}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }
}
export default Companies;
