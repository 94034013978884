import React from "react";
import { Api } from "../api/index";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import {Link} from "react-router-dom";

import CreateAccount from "./CreateAccount";
import UpdateIntegration from "./UpdateIntegration";
import Button from "react-bootstrap/Button";



class Company extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      accounts: [],
      companyId: null,
      companyName: null,
      accountModelOpen: false,
      integrationModelOpen: false
    };
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    const { companyName } = this.props.location.state || { companyName: '' }
    this.setState({ companyId: params.companyId, companyName }, () => this.reloadAccounts())
  }

  reloadAccounts = () => {
    Api.get(`/companies/${this.state.companyId}/accounts`).then((response) => {
      this.setState({ accounts: response.data });
    })
  }

  handleOpenCallback = (isOpen, isNewAccountCreated) =>{
    this.setState({accountModelOpen: isOpen})
    if (isNewAccountCreated) this.reloadAccounts()
  }

  handleIntegrationOpenCallback = (isOpen) => this.setState({integrationModelOpen: isOpen})


  render() {
    return (
      <Row className="justify-content-md-center mt-5">
        <Col xs="12" className="text-center">
          <h2>{this.state.companyName}</h2>
        </Col>
        <CreateAccount show={this.state.accountModelOpen}
                       companyId={this.state.companyId}
                       openCallback = {this.handleOpenCallback}/>
        <UpdateIntegration show={this.state.integrationModelOpen}
                           companyId={this.state.companyId}
                           openCallback = {this.handleIntegrationOpenCallback}>

        </UpdateIntegration>
        <Col xs="12" className="text-left">
          <Button variant="primary" onClick={() => this.setState({ accountModelOpen: true })}>
            Create account
          </Button>
        </Col>
        <Col xs="12" className="text-left mt-4">
          <Button variant="primary"
                  onClick={() => this.setState({ integrationModelOpen: true })}>
            Update integration
          </Button>
        </Col>
        <Col xs="12" className="text-left mt-4">
            <Link variant="primary" to={ {pathname: '/react_app' } }>
                Companies
            </Link>
        </Col>
        <Col xs="12" lg="6">
          <ListGroup>
            {this.state.accounts.map((account) =>
              <ListGroup.Item key={account.id}>
                <Link to={
                  {pathname: `/react_app/${this.state.companyId}/company/${account.id}`,
                    state: { companyName: this.state.companyName, accountName: account.name }}
                }>{account.name}</Link>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    )
  }
}
export default Company;
