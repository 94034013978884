import React from "react";
import { Api } from "../api/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      stats: {},
      companyId: null,
      accountId: null,
      companyName: null,
      accountName: null
    };
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    const { companyName, accountName } = this.props.location.state || { companyName: '', accountName: '' }
    this.setState({ companyId: params.companyId, accountId: params.accountId, companyName, accountName });
    Api.get(`/companies/${params.companyId}/accounts/${params.accountId}/users`)
      .then((response) => {
      this.setState({ users: response.data.users, stats: response.data.stats });
    })
  }
  render() {
    return (
      <Row className="justify-content-center mt-5">
          <Col xs="12" className="text-center">
            <h2>{this.state.companyName}</h2>
            <h4>{this.state.accountName}</h4>
          </Col>
          <Col xs="12">
            <div>
              Last 30 days Total tasks: <Badge variant="secondary">{this.state.stats.total_tasks}</Badge>
            </div>
            <div className="mt-2">
              Last 30 days Completed tasks: <Badge variant="secondary">{this.state.stats.completed_tasks}</Badge>
            </div>
            <div className="mt-2">
              Total out of order items: <Badge variant="secondary">{this.state.stats.broken_item_count}</Badge>
            </div>
            <div className="mt-2">
              Total Chat messages: <Badge variant="secondary">{this.state.stats.chat_message_count}</Badge>
            </div>
          </Col>
          <Col xs="12" className="mt-5">
            <table>
              <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Chat messages</th>
                <th>Sign in count</th>
                <th>Last signed in</th>
                <th>Invitation status</th>
                <th>Login Link</th>
              </tr>
              </thead>
              <tbody>
              {this.state.users.map((user) =>
                <tr key={user.id}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.role.split('_').join(' ')}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.chat_message_count || 0}</td>
                  <td>{user.sign_in_count}</td>
                  <td>{user.current_sign_in_at}</td>
                  <td>{user.invitation_status.split('_').join(' ')}</td>
                  <td>
                    { user.can_login ?
                      (
                        <a href={`/companies/${this.state.companyId}/accounts/${this.state.accountId}/users/${user.id}/api_login`}
                           target="_blank">Login
                        </a>
                      ) : null
                    }
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </Col>


      </Row>
    )
  }
}
export default Account;
