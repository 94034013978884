import React from "react";

import { Api } from "../api/index";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import DeleteUserModal from "./DeleteUserModal";
import {Link} from "react-router-dom";



class UserSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { users: [], isLoading: false, searchTerm: '', deletingUser: false, showDeleteUserModel: false,
            userToDelete: {} };
    }
    searchDisabled =() => {
        if (this.state.isLoading === true) return true
        return String(this.state.searchTerm).trim().length < 3
    }

    onDeleteUser = (userId) => {
        const userToDelete = this.state.users.find((user) => user.id === userId)
        if (userToDelete) {
            this.setState({ userToDelete, showDeleteUserModel: true })
        }
    }
    onCloseDeleteUserModal = (isDeleted) =>{
        const userId = this.state.userToDelete.id
        this.setState({ userToDelete: {}, deletingUser: false, showDeleteUserModel: false })
        if (isDeleted !== true) return
        const users = this.state.users.filter((user) => user.id !== userId)
        this.setState({ users })
    }

    searchUser = () => {
        Api.get(`/users/search?search_term=${this.state.searchTerm}`)
            .then((response) => {
                const users = response.data.users.map((user) => {
                    const userAccounts = user.accounts || []
                    user.accounts = userAccounts.join(', ')
                    return user
                })
                this.setState({ users });
            })
            .catch((err) => alert(err))
            .finally(() => this.setState({isLoading: false}))
    }
    render() {
        return (
            <Container fluid>
                <DeleteUserModal
                    show={this.state.showDeleteUserModel}
                    userToDelete={this.state.userToDelete}
                    onClose={this.onCloseDeleteUserModal}>
                </DeleteUserModal>
                <Row className="justify-content-center align-items-center mt-5">
                    <Col xs="12" className="text-left mt-3">
                        <Link variant="primary" to={ {pathname: '/react_app' } }>
                            Companies
                        </Link>
                    </Col>
                    <Col xs="10" lg="6" >
                        <Form.Label htmlFor="userSearch">Search For users</Form.Label>
                        <Form.Control
                            type="text"
                            id="userSearch"
                            placeholder="Email, phone or name"
                            aria-describedby="userSearchDescription"
                            onChange={(value) => this.setState({searchTerm: value.target.value})}
                            value={this.state.searchTerm}
                        ></Form.Control>
                        <Form.Text id="userSearchDescription" muted>
                            Type email, name or phone number of the user.
                        </Form.Text>
                    </Col>
                    <Col xs="2" className="mt-2">
                        <Button variant="primary"
                                disabled={this.searchDisabled()}
                                onClick={() => this.searchUser()}>
                            Search
                        </Button>
                    </Col>
                </Row>
                {this.state.users.length > 0 &&
                    <Row className="mt-5">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>phone</th>
                                    <th>email</th>
                                    <th>company</th>
                                    <th>accounts</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.users.map((user) =>
                                <tr key={user.id}>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td>{user.company_name}</td>
                                    <td>{user.accounts}</td>
                                    <td>
                                        <Button variant="primary" size="sm"
                                                disabled={this.searchDisabled() || this.state.deletingUser}
                                                onClick={() => this.onDeleteUser(user.id)}>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Row>
                }

            </Container>


        )
    }
}
export default UserSearch;
