import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Companies from "../components/Companies";
import Company from "../components/Company";
import Account from "../components/Account";
import Guidance from "../components/Guidance";
import AppInstalls from "../components/AppInstalls";
import UserSearch from "../components/UserSearch";
import Container from 'react-bootstrap/Container';

export default (
  <Container>
    <Router>
      <Switch>
        <Route path="/react_app/" exact component={Companies} />
        <Route path="/react_app/:companyId/company" exact component={Company} />
        <Route path="/react_app/:companyId/company/:accountId" exact component={Account} />
        <Route path="/react_app/Guidance" exact component={Guidance} />
        <Route path="/react_app/app_installs" exact component={AppInstalls} />
        <Route path="/react_app/user_search" exact component={UserSearch} />
      </Switch>
    </Router>
  </Container>
);