import axios from 'axios';
const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")

const Api = axios.create({
  baseURL: `/`,
  headers: {
    'X-CSRF-Token': csrf,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
Api.interceptors.response.use(undefined, (err) => {
  return new Promise((resolve, reject) => {
    if (err.response && err.response.status === 401) {
      window.location.replace('/sign_in');
    }
    return reject(err)
  })
})

export { Api }