import React from "react";
import { Api } from "../api/index";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import AccountTimeZoneSelect from "./AccountTimeZoneSelect";

class CreateCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = { companyName: '', accountName: '', timeZone: 'Asia/Jerusalem',
      user: { firstName: '', lastName: '', email: '', phone: '', role: 'company_admin' },
      isLoading: false };
  }

  onTrigger = (isOpen, isCreatedNewCompany) => {
    this.props.openCallback(isOpen, isCreatedNewCompany);
  }

  updateUser = (field, value) => {
    const user = {...this.state.user}
    user[field] = value
    this.setState({ user })
  }

  isEmptyString = (str) => ((str || '').trim().length === 0)

  saveDisabled =() => {
    if (this.state.isLoading === true ) return true
    if (this.isEmptyString(this.state.companyName)) return true
    if (this.isEmptyString(this.state.accountName)) return true
    if (this.isEmptyString(this.state.timeZone)) return true

    const user = this.state.user
    if (this.isEmptyString(user.firstName)) return true
    if (this.isEmptyString(user.lastName)) return true
    if (this.isEmptyString(user.role)) return true

    if (this.isEmptyString(user.phone) && this.isEmptyString(user.email)) return true
    return false
  }

  saveForm =() => {
    if (this.saveDisabled()) {
      return alert('Please fill form correctly')
    }
    this.setState({ isLoading: true })
    const user = this.state.user
    const requestParams = { company_name:  this.state.companyName,
      account_name: this.state.accountName,
      time_zone: this.state.timeZone,
      user: { first_name: user.firstName,
        last_name: user.lastName, phone: user.phone, email: user.email, role: user.role }}
    Api.post(`/companies`, { company: requestParams })
      .then(() => this.onTrigger(false, true))
      .catch((err) => alert(err))
      .finally(() => this.setState({isLoading: false}))
  }
  render() {
    return (
      <Modal show={ this.props.show } dialogClassName="create-company">
        <Modal.Header>
          <Modal.Title> Create Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form>
              <Row className="mt-3 px-4">
                <Col xs="4" className="mt-2">
                  <Form.Label>Company name</Form.Label>
                  <Form.Control required type="text" placeholder="Company name"
                                onChange={(value) =>
                                  this.setState({companyName: value.target.value})}
                                value={this.state.companyName}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>Account name</Form.Label>
                  <Form.Control
                    required type="text" placeholder="Account name"
                    onChange={(value) =>
                      this.setState({accountName: value.target.value})}
                    value={this.state.accountName}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>Time zone</Form.Label>
                  <AccountTimeZoneSelect
                    onChange={(value) => this.setState({timeZone: value.target.value})}
                    value={this.state.timeZone}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>User First name</Form.Label>
                  <Form.Control
                    required type="text" placeholder="User First name"
                    onChange={(value) =>
                      this.updateUser('firstName', value.target.value)}
                    value={this.state.user.firstName}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>User Last name</Form.Label>
                  <Form.Control
                    required type="text" placeholder="User Last name"
                    onChange={(value) =>
                      this.updateUser('lastName', value.target.value)}
                    value={this.state.user.lastName}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>User role</Form.Label>
                  <Form.Control as="select"
                    required
                    onChange={(value) =>
                      this.updateUser('role', value.target.value)}
                                value={this.state.user.role}>
                    <option value="account_admin">Account admin</option>
                    <option value="company_admin">Company admin</option>
                  </Form.Control>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>User email</Form.Label>
                  <Form.Control
                    required type="text" placeholder="User email"
                    onChange={(value) =>
                      this.updateUser('email', value.target.value)}
                    value={this.state.user.email}/>
                </Col>
                <Col xs="4" className="mt-2">
                  <Form.Label>User phone</Form.Label>
                  <Form.Control
                    required type="text" placeholder="User phone"
                    onChange={(value) =>
                      this.updateUser('phone', value.target.value)}
                    value={this.state.user.phone}/>
                </Col>
              </Row>
            </Form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"
                  onClick={() => this.onTrigger(false)}>
            Close
          </Button>
          <Button variant="primary"
                  onClick={() => this.saveForm()}>
            {this.state.isLoading && <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>}
            {!this.state.isLoading && 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default CreateCompany;
