import React from "react";
import { Api } from "../api/index";
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

import CreateCompany from "./CreateCompany";
import Button from "react-bootstrap/Button";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companyModelOpen: false
    };
  }
  componentDidMount() {
    this.reloadCompanies()
  }

  reloadCompanies = () => {
    Api.get('/companies').then((response) => {
      this.setState({ companies: response.data });
    })
  }

  handleOpenCallback = (isOpen, isNewCompanyCreated) =>{
    this.setState({ companyModelOpen: isOpen })
    if (isNewCompanyCreated) this.reloadCompanies()
  }


  render() {
    return (
        <Row className="justify-content-md-center mt-5">
          <CreateCompany show={this.state.companyModelOpen}
                         openCallback = {this.handleOpenCallback}/>
          <Col xs="12" className="text-left">
            <Button variant="primary" onClick={() => this.setState({ companyModelOpen: true })}>
              Create Company
            </Button>
          </Col>
          <Col xs="12" className="text-left mt-3">
            <Link variant="primary" to={ {pathname: '/react_app/Guidance' } }>
              Guidance
            </Link>
          </Col>
          <Col xs="12" className="text-left">
            <Link variant="primary" to={ {pathname: '/react_app/app_installs' } }>
                App installs
            </Link>
          </Col>
          <Col xs="12" className="text-left">
                <Link variant="primary" to={ {pathname: '/react_app/user_search' } }>
                    User Search
                </Link>
          </Col>
          <Col xs="12" lg="6">
            <ListGroup>
              {this.state.companies.map((company) =>
                <ListGroup.Item key={company.id}>
                  <Link to={ {pathname: `/react_app/${company.id}/company`, state: { companyName: company.name} } }>
                    {company.name}
                  </Link>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      )
  }
}
export default Companies;
