import React from "react";
import { Api } from "../api/index";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import AccountTimeZoneSelect from './AccountTimeZoneSelect';

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { accounts: [this.defaultAccount()], isLoading: false };
  }

  defaultAccount = () => ({ name: '', timeZone: 'Asia/Jerusalem'})

  onTrigger = (isOpen, isCreatedNewAccounts) => {
    this.props.openCallback(isOpen, isCreatedNewAccounts);
  }

  addAccount = () => {
    this.setState(state => {
      const accounts = state.accounts.concat(this.defaultAccount());
      return { accounts }
    })
  }

  removeAccount = (index) => {
    this.setState(state => {
      const accountsCopy = [...state.accounts]
      accountsCopy.splice(index, 1);
      return { accounts: accountsCopy }
    })
  }

  updateArrayItem = (index, field, value) => {
    this.setState(state => {
      const accountsCopy = [...state.accounts]
      accountsCopy[index][field] = value
      return { accounts: accountsCopy }
    });
  }

  saveDisabled =() => {
    if (this.state.accounts.length === 0 || this.state.isLoading === true ) return true
    return this.state.accounts.some((account) => {
      return (account.name || '').trim().length === 0 || (account.timeZone || '').trim().length === 0
    })
  }

  saveForm =() => {
    if (this.saveDisabled()) {
      return alert('Please fill form correctly')
    }
    this.setState({ isLoading: true })
    const requestParams = this.state.accounts.map((account) => ({ name: account.name, time_zone: account.timeZone }))
    Api.post(`/companies/${this.props.companyId}/accounts`, { create_params: requestParams })
      .then(() => this.onTrigger(false, true))
      .catch((err) => alert(err))
      .finally(() => this.setState({isLoading: false}))
  }
  render() {
    return (
      <Modal show={ this.props.show } >
        <Modal.Header>
          <Modal.Title> Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form>
              {this.state.accounts.map((account, index) =>
                <Row key={index} className="mt-3">
                  <Col xs="5">
                    <Form.Label>Account name</Form.Label>
                    <Form.Control required type="text" placeholder="Account name"
                                  onChange={(value) =>
                                    this.updateArrayItem(index, 'name', value.target.value)}
                                  value={account.name}/>
                  </Col>
                  <Col xs="5">
                    <Form.Label>Time zone</Form.Label>
                    <AccountTimeZoneSelect
                      onChange={(value) =>
                        this.updateArrayItem(index, 'timeZone', value.target.value)}
                      value={account.timeZone}/>
                  </Col>
                  <Col xs="2" className="align-self-end">
                    <Button variant="success" onClick={() => this.removeAccount(index)}>-</Button>
                  </Col>
                </Row>
              )}
            </Form>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button variant="success" onClick={this.addAccount}>+</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"
                  onClick={() => this.onTrigger(false)}>
            Close
          </Button>
          <Button variant="primary"
                  onClick={() => this.saveForm()}>
            {this.state.isLoading && <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>}
            {!this.state.isLoading && 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default CreateAccount;
