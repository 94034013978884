import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import {Api} from "../api";

class CreateGuidanceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, guidanceRole: '', guidanceTitle: '', guidanceLink: '',
            guidanceLang: '', guidanceId: null };
    }


    close = (isCreated) => {
        this.setState({ isLoading: false, guidanceRole: '', guidanceTitle: '', guidanceLink: '',
            guidanceLang: '', guidanceId: null })
        this.props.onClose( isCreated);
    }



    saveDisabled =() => {
        if ( this.state.isLoading === true ) return true
        if ( this.state.guidanceRole.trim().length === 0 ) return true
        if ( this.state.guidanceLang.trim().length === 0 ) return true
        if ( this.state.guidanceTitle.trim().length === 0 ) return true
        return false
    }

    saveForm =() => {
        if (this.saveDisabled()) {
            return alert('Please fill form correctly')
        }
        this.setState({ isLoading: true })
        const data = { role: this.state.guidanceRole, lang: this.state.guidanceLang,
            guide_data: { title: this.state.guidanceTitle, link: this.state.guidanceLink}}
        const apiCall = this.state.guidanceId === null ?
            Api.post('/system_guidances', { system_guidance: data }) :
            Api.put(`/system_guidances/${this.state.guidanceId}`, { system_guidance: data })

        apiCall
            .then(() => this.close(true))
            .catch((err) => alert(err))
            .finally(() => this.setState({isLoading: false}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show === false && this.props.show === true && this.props.guidanceForUpdate !== null) {
            const guidanceForUpdate = this.props.guidanceForUpdate
            this.setState({ guidanceRole: guidanceForUpdate.role,
                guidanceLink: guidanceForUpdate.guide_data.link, guidanceId: guidanceForUpdate.id,
                guidanceTitle: guidanceForUpdate.guide_data.title, guidanceLang: guidanceForUpdate.lang })
        }
        if (prevProps.show === true && this.props.show === false) {
            this.setState({ isLoading: false, guidanceRole: '', guidanceTitle: '', guidanceLink: '',
                guidanceLang: 'en', guidanceId: null })
        }
    }
    render() {
        return (
            <Modal show={ this.props.show } >
                <Modal.Header>
                    <Modal.Title> Create Guidance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form>
                            <Form.Label>Permission</Form.Label>
                            <Form.Select required
                                         onChange={(event) =>
                                             this.setState({guidanceRole: event.target.value})}
                                         value={this.state.guidanceRole}>
                                <option value="">Please select permission</option>
                                <option value="account_employee">Employee</option>
                                <option value="account_manager">Department</option>
                                <option value="account_admin">Manager</option>
                                <option value="company_admin">HQ</option>
                            </Form.Select>
                            <Form.Label className="mt-3">Language</Form.Label>
                            <Form.Select required
                                         onChange={(event) =>
                                             this.setState({guidanceLang: event.target.value})}
                                         value={this.state.guidanceLang}>
                                <option value="">Please select language</option>
                                <option value="en">English</option>
                                <option value="he">Hebrew</option>
                            </Form.Select>
                            <Form.Label className="mt-3">Title</Form.Label>
                            <Form.Control required as="textarea" placeholder="Title"
                                          onChange={(value) =>
                                              this.setState({guidanceTitle: value.target.value})}
                                            value={this.state.guidanceTitle}/>
                            <Form.Label className="mt-3">Link</Form.Label>
                            <Form.Control required type="text" placeholder="Title"
                                          onChange={(value) =>
                                              this.setState({guidanceLink: value.target.value})}
                                          value={this.state.guidanceLink}/>
                        </Form>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => this.close(false)}>
                        Close
                    </Button>
                    <Button variant="primary"
                            disabled={this.saveDisabled()}
                            onClick={() => this.saveForm()}>
                        {this.state.isLoading && <Spinner animation="border" role="status">
                            <span className="sr-only"></span>
                        </Spinner>}
                        {!this.state.isLoading && 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default CreateGuidanceModal;

