import React from "react";

import Form from 'react-bootstrap/Form';

class AccountTimeZoneSelect extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      timeZones: ['Asia/Jerusalem', 'America/New_York', 'Europe/London']
    };
  }

  render() {
    return (
      <Form.Control as="select" value={this.props.value} onChange={this.props.onChange}>
        {this.state.timeZones.map((timeZone, index) =>
          <option key={index}>{timeZone}</option>
        )}
      </Form.Control>
    )
  }
}
export default AccountTimeZoneSelect;
