import React from "react";
import { Api } from "../api/index";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

class UpdateIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  workspaceCode: '', isLoading: true };
        this.integrationTypeName = 'mybites';
    }


    onTrigger = (isOpen) => this.props.openCallback(isOpen);

    saveDisabled =() => this.state.isLoading === true


    loadIntegrations = () => {
        Api.get(`/companies/${this.props.companyId}/company_integrations/`)
            .then((response) => {
                const responseDate = response.data;
                if (!Array.isArray(responseDate)) return
                const integration = responseDate.find((integration) => integration.integration_type === this.integrationTypeName)
                const workspaceCode = integration ? integration.extra_values.workspace_code : ''
                this.setState({workspaceCode: workspaceCode})
            })
            .catch((err) => alert(err))
            .finally(() => this.setState({isLoading: false}))
    }
    saveForm =() => {
        if (this.saveDisabled()) {
            return alert('Please fill form correctly')
        }
        this.setState({ isLoading: true })
        const requestParams = { extra_value: { workspace_code: this.state.workspaceCode },
            integration_type: this.integrationTypeName }
        Api.put(`/companies/${this.props.companyId}/company_integrations/upsert`, requestParams)
            .then(() => this.onTrigger(false))
            .catch((err) => alert(err))
            .finally(() => this.setState({isLoading: false}))
    }
    render() {
        return (
            <Modal show={ this.props.show }
                   onEnter = { this.loadIntegrations}>
                <Modal.Header>
                    <Modal.Title> Update bites workspace code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs="10" className="mt-2">
                                <Form.Label>Workspace code</Form.Label>
                                <Form.Control required type="text" placeholder="Workspace code"
                                              onChange={(value) =>
                                                  this.setState({workspaceCode: value.target.value})}
                                              value={this.state.workspaceCode}/>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => this.onTrigger(false)}>
                        Close
                    </Button>
                    <Button variant="primary"
                            disabled={this.saveDisabled()}
                            onClick={() => this.saveForm()}>
                        {this.state.isLoading && <Spinner animation="border" role="status">
                            <span className="sr-only"></span>
                        </Spinner>}
                        {!this.state.isLoading && 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default UpdateIntegration;
