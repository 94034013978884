import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import CreateGuidanceModal from "./CreateGuidanceModal";
import {Api} from "../api";
import {Link} from "react-router-dom";

class Guidance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateGuideModal: false,
            guidanceForUpdate: null,
            roles: [
                {name: 'company_admin', title: 'HQ'},
                {name: 'account_admin', title: 'Manager'},
                {name: 'account_manager', title: 'Department'},
                {name: 'account_employee', title: 'Employee'}
            ],
            guidanceRows: []
        };
    }
    reloadSystemGuidances = () => {
        Api.get('/system_guidances').then((response) =>
            this.setState({ guidanceRows: response.data })
        )
        .catch((err) => alert(err))
    }
    componentDidMount() {
        this.reloadSystemGuidances()
    }

    onCloseCreateModal = (isCreated) =>{
        this.setState({ showCreateGuideModal: false, guidanceForUpdate: null });
        if (isCreated !== true) return
        this.reloadSystemGuidances()
    }

    openModalForUpdate = (guidance) => {
        this.setState({guidanceForUpdate: guidance, showCreateGuideModal: true})
    }

    deleteGuidance = (guidance) => {
        Api.delete(`/system_guidances/${guidance.id}`).then(() =>
            this.reloadSystemGuidances()
        )
            .catch((err) => alert(err))
    }
    render() {
        return (
            <Row className="justify-content-center mt-5">
                <Col xs="12" className="text-left">
                    <Button variant="primary" className="mt-3"
                            onClick={() => this.setState({showCreateGuideModal: true,
                                guidanceForUpdate: null})}>
                        Add guide
                    </Button>
                </Col>
                <Col xs="12" className="text-left mt-3">
                    <Link variant="primary" to={ {pathname: '/react_app' } }>
                        Companies
                    </Link>
                </Col>
                <Col xs="12" lg="6">
                    <Accordion defaultActiveKey="0">
                        {this.state.roles.map((role, index) =>
                            <Accordion.Item eventKey={index.toString()} key={`item_${role.name}`}>
                                <Accordion.Header key={`header_${role.name}`}>{role.title}</Accordion.Header>
                                <Accordion.Body key={`body_${role.name}`}>
                                    {this.state.guidanceRows
                                        .filter((guidance)=> guidance.role === role.name).map((guidance) =>
                                            <Card key={guidance.id}>
                                                <Card.Body>
                                                    <Card.Title>{guidance.guide_data.title}</Card.Title>
                                                    <Badge bg="primary">{guidance.lang}</Badge>
                                                    <Card.Text>
                                                        {guidance.guide_data.link.length > 0 &&
                                                            <iframe width="420" height="315" className="mt-2"
                                                                    src={guidance.guide_data.link}>
                                                            </iframe>
                                                        }
                                                    </Card.Text>
                                                    <div>
                                                        <Button variant="primary" className="me-2"
                                                                onClick={() => this.openModalForUpdate(guidance)}
                                                                disabled={this.state.showCreateGuideModal === true}>
                                                            Update
                                                        </Button>
                                                        <Button variant="danger"
                                                                onClick={() => this.deleteGuidance(guidance)}
                                                                disabled={this.state.showCreateGuideModal === true}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
                    <CreateGuidanceModal
                        show={this.state.showCreateGuideModal}
                        guidanceForUpdate={this.state.guidanceForUpdate}
                        onClose={this.onCloseCreateModal}>
                    </CreateGuidanceModal>
                </Col>
            </Row>
        )
    }
}
export default Guidance;